.form-label {
  font-size: 1.6em;
  font-weight: 400;
}
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
}
.error-message {
  color: red;
}
@media (hover: none) {
  #send-button {
    background-color: var(--color-foreground);
    box-shadow: none;
  }
}
@media (hover: hover) {
  #send-button {
    background-color: #b2c44b;
    box-shadow: 0px 2px 4px -1pxrgba (0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }
}
