.middle-icon {
  width: 10rem;
  height: 10rem;
  position: fixed;
  top: 50%;
  transform: translate(0px, -50%);
  opacity: 0;
}
.middle-icon-image {
  position: absolute;
  max-width: 100%;
  height: auto;
}
.circular-arrow-people {
  transform: rotate(0deg);
}
