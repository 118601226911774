:root {
  --color-background: #ffffeb;
  --color-primary: #00453d;
  --color-on-primary: #ffffff;
  --color-accent: #ab6321;
  --color-foreground: #c5da53;
  --zh-TW-font-family: "Noto Serif TC", serif;
  --zh-font-family: "Noto Serif TC", serif;
  --en-font-family: "EB Garamond", serif;
  --main-font-family: var(--zh-TW-font-family);
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: var(--main-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  /* --scroll: 0; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html:lang("en") {
  --main-font-family: var(--en-font-family);
}
/* cormorant-garamond-regular - latin */
@font-face {
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("./fonts/cormorant-garamond-v15-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/cormorant-garamond-v15-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* cormorant-garamond-600 - latin */
@font-face {
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("./fonts/cormorant-garamond-v15-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/cormorant-garamond-v15-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* eb-garamond-regular - latin */
@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("./fonts/eb-garamond-v25-latin-regular.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/eb-garamond-v25-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* eb-garamond-600 - latin */
@font-face {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("./fonts/eb-garamond-v25-latin-600.woff2") format("woff2"),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url("./fonts/eb-garamond-v25-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-serif-tc-regular - latin_chinese-traditional */
/* @font-face {
  font-family: "Noto Serif TC";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(""), url("./fonts/noto-serif-tc-v23-latin_chinese-traditional-regular.woff2") format("woff2"),
    url("./fonts/noto-serif-tc-v23-latin_chinese-traditional-regular.woff") format("woff");
} */
/* noto-serif-tc-600 - latin_chinese-traditional */
/* @font-face {
  font-family: "Noto Serif TC";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local(""), url("./fonts/noto-serif-tc-v23-latin_chinese-traditional-600.woff2") format("woff2"),
    url("./fonts/noto-serif-tc-v23-latin_chinese-traditional-600.woff") format("woff");
} */
