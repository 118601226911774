.title-bar {
  background-color: var(--color-primary);
  color: var(--color-on-primary);
  height: 6.4rem;
  width: 100%;
  text-align: center;
  line-height: 6.4rem;
  font-size: 3.2rem;
  font-weight: 400;
  margin: 0px;
}
.sub-main-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 2rem;
  padding: 2rem;
  color: #00453d;
  width: 100%;
}
.sec-container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  width: 100%;
}
.sec-title {
  font-size: 2.4rem;
}
.sec-detail {
  font-size: 1.6rem;
  line-height: 3.2rem;
  letter-spacing: 0.1em;
}
.divider {
  width: 90%;
  height: 2px;
  margin: 8rem 0px;
  background: linear-gradient(90deg, rgba(111, 186, 44, 0) 0.14%, rgba(111, 186, 44, 0) 0.14%, #00453d 51.18%, rgba(111, 186, 44, 0) 100.14%);
}
@media screen and (max-width: 599px) {
  .divider {
    margin: 2rem 0px;
  }
  .sub-main-content {
    width: 100%;
  }
}
@media screen and (min-width: 600px) {
  .divider {
    margin: 4rem 0px;
  }
  .sub-main-content {
    width: 80%;
  }
}
@media screen and (min-width: 1440px) {
  .divider {
    margin: 8rem 0px;
  }
  .sub-main-content {
    width: 80%;
  }
}
