.app-bar {
  box-sizing: border-box;
  display: flex;
  background-color: transparent;
  height: 6rem;
  padding: 0 2rem;
  position: sticky;
  top: 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
}

.appbar-top-shader {
  background: linear-gradient(180deg, #FFFFEB 84.37%, rgba(255, 255, 235, 0) 100%);
  width: 100vw;
  height: 6rem;
  position: fixed;
  top: 0px;
}

.app-logo {
  pointer-events: none;
  width: 2.8rem;
  height: 4rem;
}

.bar-logo-name {
  font-size: 2.4rem;
  font-weight: 600;
  pointer-events: none;
  font-family: "Cormorant Garamond", serif;
  color: var(--color-primary);
}

.horizontal-placeholder {
  flex-grow: 1;
  pointer-events: none;
}
.menu-button {
  display: block;
}
a {
  text-decoration: none;
}
.app-bar-button {
  display: none;
  z-index: 10;
}
.menu-anchor {
  width: 100%;
  text-align: center;
}
.menu-anchor:link, .menu-anchor:visited {
  color: var(--color-on-primary);
}

/* a:hover {
  color: red;
} */

@media screen and (max-width: 599px) {
  .menu-button {
    display: block !important;
  }
  .app-bar-button {
    display: none !important;
  }
}
@media screen and (min-width: 600px) {
  .menu-button {
    display: none !important;
  }
  .app-bar-button {
    display: block !important;
  }
}
