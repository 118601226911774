.App {
  text-align: center;
}

.grid-container {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  padding: 0px;
}
.bg {
  background: url("./images/background-image.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  height: 100vh;
  width: calc(100% / 8 * 6);
  margin: 0px calc(100% / 8 * 1);
}

.main-content {
  grid-column: 1 / span 4;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 0px;
  /* z-index: 0; */
}
.logo-with-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  color: var(--color-primary);
  font-size: 6rem;
  font-weight: 600;
  font-family: "Cormorant Garamond", serif;
  text-align: center;
  padding: 0px;
  margin: 0px;
  position: fixed;
  top: 55%;
  left: 50%;
  transform: translate(calc(-50% + 0.5rem), -50%);
  /* width: 100%; */
  animation-name: logo-with-name;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  /* z-index: 0; */
}
.logo-name {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  position: relative;
  opacity: 0;
}
.name-left {
  animation-name: logo-name-left;
  left: -30vw;
}
.name-right {
  animation-name: logo-name-right;
  right: -30vw;
}
.logo-svg {
  pointer-events: none;
}
.company-name {
  position: fixed;
  top: 110vh;
  font-size: 4.8rem;
  font-weight: 600;
  animation-name: company-name;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  color: var(--color-primary);
  /* z-index: 0; */
}
.company-name-finished {
  position: fixed;
  top: 50%;
  font-size: 3.6rem;
  font-family: "Cormorant Garamond", serif;
  font-weight: 600;
  color: var(--color-primary);
  opacity: 0;
  transform: translate(0, -50%);
}
.slogan {
  position: fixed;
  bottom: -20vh;
  font-size: 4.8rem;
  font-weight: 600;
  font-family: "Cormorant Garamond", serif;
  color: var(--color-primary);
  opacity: 1;
  max-width: 51rem;
}
.top-shader {
  background: linear-gradient(180deg, #FFFFEB 40.93%, rgba(255, 255, 235, 0.65) 81.96%, rgba(255, 255, 235, 0) 100%);
  width: 100%;
  height: 15rem;
  position: fixed;
  z-index: 1;
  top: 0px;
}
.middle-shader {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 235, 0) 0%,
    #ffffeb 18.23%,
    #ffffeb 82.81%,
    rgba(255, 255, 235, 0) 100%
  );
  width: 8px;
  height: 30vh;
  position: fixed;
  z-index: -1;
  top: 50%;
  transform: translate(0px, -50%);
}
.middle-text{
  font-size: 3.2rem;
  font-weight: 600;
  position:fixed;
  top: 50%;
  color: var(--color-primary);
  opacity: 0;
  max-width: 15rem;
}
.middle-text:lang(en){
  font-size: 2rem;

}
.middle-text-left {
  transform: translate(-60%, calc(-50% - 15vh));
  text-align: right;
}
.middle-text-right{
  transform: translate(60%, calc(-50% + 15vh));
  text-align: left;
}
.content-container {
  position: relative;
  height: 1050vh;
  width: 100%;
}
.gradient-line {
  position: relative;
  width: 6px;
  margin: 0px auto;
  height: calc(100% - 50vh);
  background: linear-gradient(
    180deg,
    rgba(197, 218, 83, 0) 0%,
    #a7cf57 8.25%,
    #6fba2c 29.97%,
    #25983a 50.06%,
    #246835 74.32%,
    #174624 99.26%,
    rgba(20, 62, 32, 0) 100%
  );
  margin-top: 100vh;
  animation-name: gradient-line;
  animation-duration: 1s;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  z-index: -2;
}
@media screen and (max-width: 599px) {
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .main-content {
    grid-column: 1 / span 4;
  }
  .bg {
    width: 100vw;
    margin: 0px;
  }
}
@media screen and (min-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(8, 1fr);
  }
  .main-content {
    grid-column: 2 / span 6;
  }
  .bg {
    width: calc(100% / 8 * 6);
    margin: 0px calc(100% / 8 * 1);
  }
}
@media screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: repeat(12, 1fr);
  }
  .main-content {
    grid-column: 3 / span 8;
  }
  .bg {
    width: calc(100% / 12 * 8);
    margin: 0px calc(100% / 12 * 2);
  }
}
@media only screen and (orientation: landscape) {
}
.logo-path {
  position: relative;
  animation-name: logo-svg;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  opacity: 0;
}
#logo-path-1 {
  transform: translate(-195%, -221%) rotate(-44.31deg);
}
#logo-path-2 {
  transform: translate(160%, -81%) rotate(-94.17deg);
}
#logo-path-3 {
  transform: translate(86%, 201%) rotate(15.94deg);
}
#logo-path-4 {
  transform: translate(4%, -151%) rotate(43.58deg);
}
#logo-path-5 {
  transform: translate(-4%, 291%) rotate(54.99deg);
}
#logo-path-6 {
  transform: translate(-145%, 201%) rotate(-27.86deg);
}
#logo-path-7 {
  transform: translate(145%, 101%) rotate(14.16deg);
}
#logo-path-8 {
  transform: translate(145%, -151%) rotate(-10.41deg);
}
#logo-path-9 {
  transform: translate(0%, -301%) rotate(-30.41deg);
}
.deco-line {
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: rotate(0deg);
  /* z-index: 0; */
}
@keyframes logo-svg {
  to {
    transform: translate(0%, 0%) rotate(0deg);
    opacity: 1;
  }
}
@keyframes logo-name-left {
  to {
    opacity: 1;
    left: 0%;
  }
}
@keyframes logo-name-right {
  to {
    opacity: 1;
    right: 0%;
  }
}
@keyframes logo-with-name {
  to {
    top: 48%;
  }
}
@keyframes company-name {
  to {
    top: 55%;
    font-size: 3.6rem;
  }
}
@keyframes gradient-line {
  to {
    margin-top: 50vh;
  }
}
